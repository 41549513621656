<template>
  <div>
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-checkbox
            v-model="dataGeneral.isWorkAbove30h"
            class="mb-1"
          >
            Có số giờ làm thêm trên 30 giờ/tháng
          </b-form-checkbox>
          <b-form-checkbox
            v-model="dataGeneral.rents"
            class="mb-1"
          >
            Có thuê thực hiện dịch vụ về an toàn, vệ sinh lao động trong năm
          </b-form-checkbox>
          <b-form-checkbox
            v-model="dataGeneral.oshService"
            class="mb-1"
          >
            Có thực hiện dịch vụ về an toàn, vệ sinh lao động theo quy định tại Khoản 5 Điều 72 Luật an toàn, vệ sinh lao động
          </b-form-checkbox>
          <b-form-checkbox
            v-model="dataGeneral.rentHealthService"
            class="mb-1"
          >
            Có thuê thực hiện dịch vụ về y tế trong năm
          </b-form-checkbox>
          <b-form-checkbox
            v-model="dataGeneral.healthService"
            class="mb-1"
          >
            Có thực hiện dịch vụ về y tế theo quy định tại Khoản 5 Điều 73 Luật an toàn, vệ sinh lao động
          </b-form-checkbox>
          <b-form-checkbox
            v-model="dataGeneral.evaluationPeriod"
            class="mb-1"
          >
            Có tiến hành đánh giá định kỳ nguy cơ rủi ro về an toàn, vệ sinh lao động trong năm
          </b-form-checkbox>
          <b-form-checkbox
            v-model="dataGeneral.evaluationEffective"
            class="mb-1"
          >
            Có tiến hành đánh giá hiệu quả các biện pháp phòng, chống các yếu tố nguy hiểm, yếu tố có hại theo quy định tại Điều 7 Nghị định 39/2016/NĐ-CP
          </b-form-checkbox>
          <b-form-checkbox
            v-model="dataGeneral.isSupervise"
            class="mb-1"
          >
            Đã được thanh tra, kiểm tra về an toàn, vệ sinh lao động
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số lượng các yếu tố nguy hiểm, yếu tố có hại đã được nhận diện trong năm</label>
            <b-form-input
              v-model="dataGeneral.elementImprove"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số lượng các yếu tố nguy hiểm, yếu tố có hại đã được cải thiện trong năm</label>
            <b-form-input
              v-model="dataGeneral.elementIdenty"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
  },

  props: {
    dataGeneral: {
      type: Object,
      default: _ => {},
    },
  },

  data() {
    return {
      disableProp: false,
    }
  },
}
</script>

<style>

</style>
